<template>
  <div class="dynamic-page">
    <van-nav-bar title="动态详情" />
    <div class="header">
      <div class="mt05">
        <div class="avatar">
          <van-image
            round
            width="1.4rem"
            height="1.4rem"
            :src="dynamic.avatar"
          />
        </div>
        <div class="nickname">
          <div style="float: left; width: 100%">
            <div class="nickname-1">{{dynamic.nickname}}</div>
            <span :class="dynamic.sex === 1 ? 'nickname-1-icon-man' : 'nickname-1-icon-woman'" style="margin-top: 0.25rem">
              <van-image :class="dynamic.sex === 1 ? 'nickname-1-icon-img' : 'nickname-1-icon-img-w'" :src="dynamic.sex === 1 ? require('../../assets/dynamic/man.png') : require('../../assets/dynamic/woman.png')" />
              <span class="nickname-1-icon-txt">{{dynamic.age}}</span>
              <div style="clear: both"></div>
            </span>
          </div>
          <div class="nickname-2" style=" width: 100%">{{dynamic.create_at}} · {{dynamic.location}}</div>
        </div>
        <div class="call">
          <van-image class="call-img" :src="require('../../assets/dynamic/call.png')" @click="showBox" />
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="text">
      {{dynamic.content}}
    </div>
    <div style="clear: both"></div>
    <div class="img" v-if="dynamic.dynamic_type === 2">
      <div class="mt05">
        <video id="video-player" muted="muted" preload="none" autoplay="autoplay" style="width: 50%;" controls>
          <source poster="dynamic.video_img" :src="dynamic.pic[0]" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="img" v-else>
      <div class="mt05">
        <van-image
          v-for="item in dynamic.pic"
          class="img-list"
          fit="cover"
          :src="item"
        />

      </div>
    </div>
    <div style="clear: both"></div>
    <div class="reward" v-if="dynamic.reward > 0">
      <div class="mt05" @click="showBox">
        <div class="reward-avatar">
          <van-image
            v-for="item in dynamic.reward_list"
            round
            class="reward-avatar-detail"
            :src="item"
          />
        </div>
        <div class="reward-txt">
          收到{{dynamic.reward}}个礼物
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="tool">
      <div class="mt05">
        <div class="star-btn" @click="showBox">
          <div class="star-btn1">
            <van-image class="star-btn-img" :src="require('../../assets/dynamic/star.png')" />
          </div>
          <div class="star-btn2">
            <span v-if="dynamic.heart > 0">{{dynamic.heart}}</span>
            <span v-else>点赞</span>
          </div>
        </div>
        <div class="comment-btn" @click="showBox">
          <div class="star-btn1">
            <van-image class="star-btn-img" :src="require('../../assets/dynamic/comment.png')" />
          </div>
          <div class="star-btn2">
            <span v-if="dynamic.comment_num > 0">{{dynamic.comment_num}}</span>
            <span v-else>评论</span>
          </div>
        </div>
        <div class="reward-btn">
          <van-image @click="showBox" class="reward-btn-img" :src="require('../../assets/dynamic/reward-btn1.png')" />
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="comment-list1 mt05" v-if="dynamic.comment_num > 0">
      <div class="comment-list1-item pt05" style="border-top: #f0f0f0 1px solid; margin-bottom: 0.5rem" v-for="item in dynamic.comment_list">
        <div class="avatar">
          <van-image
            round
            width="0.85rem"
            height="0.85rem"
            :src="item.avatar"
          />
        </div>
        <div class="nickname">
          <div style="float: left">
            <div class="comment-list1-item-nickname">{{item.nickname}}</div>
            <span :class="item.sex === 1 ? 'comment-list1-item-icon-man' : 'comment-list1-item-icon-woman'">
              <van-image :class="item.sex === 1 ? 'comment-list1-item-icon-img' : 'comment-list1-item-icon-img-w'" :src="item.sex === 1 ? require('../../assets/dynamic/man.png') : require('../../assets/dynamic/woman.png')" />
              <span class="nickname-1-icon-txt">{{item.age}}</span>
              <div style="clear: both"></div>
            </span>
          </div>
          <div class="nickname-2">{{item.create_at}}</div>
        </div>
        <div class="comment-star" @click="showBox">
          <van-image class="star-btn-img" :src="require('../../assets/dynamic/star.png')" />
          <span class="comment-star-num">{{item.heart}}</span>
        </div>
        <div style="clear: both"></div>
        <div class="comment-list-ctn">
          {{item.content}}
        </div>
      </div>
    </div>
    <div class="comment-list mt05" v-else>
      <span class="comment-list-txt">快来发表你的评论吧～</span>
    </div>
    <div style="clear: both"></div>
    <div style="width: 100%">
      <van-tabbar style="width: 100%">
        <van-cell-group inset style="width: 100%">
          <van-field
            class="comment-ipt"
            center
            clearable
            v-model="ipt"
            placeholder="评论一下…"
          >
            <template #button>
              <van-button style="margin-right: -0.25rem" size="small" round @click="showBox">发送</van-button>
            </template>
          </van-field>
        </van-cell-group>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
	import {NavBar, Image as VanImage, Button, Tabbar, Field, CellGroup, Dialog} from 'vant';
	import { dynamic } from '../../api'
	import {getUrlQuery} from "../../utils/common";
	export default {
		name: "index",
		components: {
			[NavBar.name]: NavBar,
			[VanImage.name]: VanImage,
			[Button.name]: Button,
			[Tabbar.name]: Tabbar,
			[Field.name]: Field,
			[CellGroup.name]: CellGroup,
			[Dialog.name]: Dialog,
			[Dialog.Component.name]: Dialog.Component,
		},
		data() {
			return {
				code: '',
        dynamic: {},
				ipt:'',
        user_id: ''
      }
		},
    created() {
	    this.code = getUrlQuery("code");
			this.getDynamic()
    },
    methods: {
	    getDynamic() {
		    dynamic({code: this.code}).then(res => {
		    	this.dynamic = res;
		    	this.user_id = res.user_id
        })
	    },
	    showBox() {
		    Dialog.confirm({
			    message: '当前H5无法进行该行为哦，下载app查看更多信息，快来app围观我吧~',
			    theme: 'round-button',
			    confirmButtonText: '马上下载',
			    cancelButtonText: '先不下载',
			    cancelButtonColor: '#878F96'
		    }).then(() => {
          this.$router.push({path: 'login', query:{id: this.user_id}})
        }).catch(() => {
          // on cancel
        });
	    }
    }
	}
</script>

<style scoped>
  .dynamic-page {
    width: 100%;
    height: 100%;
  }
  .mt05 {
    margin-top: 0.5rem;
  }
  .pt05 {
    padding-top: 0.5rem;
  }
  .header {
    width: 90%;
    margin: auto;
  }
  .avatar {
    float: left;
  }
  .nickname {
    margin-left: 0.3rem;
    float: left;
  }
  .nickname-1 {
    font-size: 0.4rem;
    color: #666666;
    font-family: PingFang SC,serif;
    font-weight: 500;
    line-height: 0.9rem;
    float: left;
  }
  .nickname-1-icon-man{
    float: left;
    border-radius: 25rem;
    background-color: #1883ff;
  }
  .nickname-1-icon-woman{
    float: left;
    border-radius: 25rem;
    background-color: #ff668f;
  }
  .nickname-1-icon-img{
    width: 11px;
    height: 11px;
    margin-top: -0.1rem;
  }
  .nickname-1-icon-img-w{
    width: 11px;
    height: 12px;
    margin-top: -0.1rem;
  }
  .nickname-1-icon-txt{
    margin-left: 0.1rem;
    font-size: 0.25rem;
    color: #ffffff;
    padding-right: 0.2rem;
  }
  .nickname-2 {
    font-family: PingFang SC,serif;
    font-weight: 500;
    color: #878F96;
  }
  .call{
    float: right;
    line-height: 1.92rem;
  }
  .call-img {
    width: 65px;
    height: 30px;
  }
  .text {
    width: 90%;
    margin: auto;
    font-size: 0.4rem;
    font-family: PingFang SC,serif;
    font-weight: 500;
    color: #666666;
    line-height: 0.7rem;
  }
  .img {
    width: 90%;
    margin: auto;
  }
  .img-list {
    width: 2.8rem;
    height: 2.8rem;
    float: left;
    margin: 0 0.2rem 0.2rem 0;
  }
  .reward {
    width: 90%;
    margin: auto;
  }
  .reward-avatar{
    float: left;
  }
  .reward-avatar-detail{
    width: 0.7rem;
    height: 0.7rem;
    margin-left: -0.1rem;
  }
  .reward-txt {
    float: left;
    line-height: 0.75rem;
    margin-left: 0.2rem;
    color: #666666;
  }
  .tool{
    width: 90%;
    margin: auto;
  }
  .star-btn{
    float: left;
  }
  .star-btn1{
    float: left;
  }
  .star-btn2{
    float: left;
    margin-left: 0.2rem;
    line-height: 0.6rem;
    font-size: 0.4rem;
    color: #666666;
  }
  .star-btn-img {
    width: 15px;
    height: 15px;
  }
  .comment-btn{
    float: left;
    margin-left: 0.5rem;
  }
  .reward-btn {
    float: right;
  }
  .reward-btn-img{
    width: 99px;
    height: 100%;
    margin-top: -0.17rem;
  }
  .comment-list{
    background-color: #999999;
    width: 100%;
    min-height: 15rem;
    text-align: center;
    padding: 1.5rem;
  }
  .comment-list-txt{
    font-size: 0.4rem;
    color: #666666;
    text-align: center;
  }
  .comment-ipt{
    background-color: #999999;
    border-radius: 25rem;
    height: 1rem;
    width: 100%;
    margin-top: 0.15rem;
  }
  .comment-list1{
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }
  .comment-list1-item{
    width: 90%;
    margin: auto;
  }
  .comment-list1-item-nickname {
    font-size: 0.35rem;
    color: #666666;
    font-family: PingFang SC,serif;
    font-weight: 500;
    line-height: 0.45rem;
    float: left;
  }
  .comment-list1-item-icon-man{
    float: left;
    border-radius: 25rem;
    background-color: #1883ff;
  }
  .comment-list1-item-icon-woman{
    float: left;
    border-radius: 25rem;
    background-color: #ff668f;
  }
  .comment-list1-item-icon-img{
    width: 11px;
    height: 11px;
    margin-top: -0.1rem;
  }
  .comment-list1-item-icon-img-w{
    width: 11px;
    height: 12px;
    margin-top: -0.1rem;
  }
  .comment-star{
    float: right;
  }
  .comment-star-num{
    font-size: 0.5rem;
    color: #878F96;
    padding: 0.01rem;
    margin-left: 0.01rem;
  }
  .comment-list-ctn {
    font-size: 0.4rem;
    font-family: PingFang SC,serif;
    font-weight: 500;
    color: #666666;
    line-height: 0.7rem;
  }
</style>
